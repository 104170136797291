<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">採購資訊</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <Grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="(row, callback) => { row.StockDate = row.Time = new Date(new Date().setHours(0, 0, 0, 0)); row.Amount = 0; row.Status = 0; row.Submitter = { Name: $user.Name }; callback(); }"
          @edit="(row, callback) => { row.OriginalStatus = row.Status; row.Password = undefined; detailGridOptions.canCreate = detailGridOptions.canUpdate = detailGridOptions.canDelete = detailGridOptions.canRead = row.Status < 30; callback(); }"
          @reset="(row, callback) => { callback(); row.Online = false; row.Password = undefined; }"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-supplier-id="{ data }">
                <SelectBox :transfer="true" v-model="data.SupplierId" v-bind="supplierSelectOptions" />
              </template>
              <template #column-warehouse-id="{ data }">
                <SelectBox :transfer="true" v-model="data.WarehouseId" v-bind="warehouseSelectOptions" />
              </template>
            </vxe-form>
            <div class="post intro-y overflow-hidden box mx-3 mt-2 border p-3">
              <div class="flex items-center ml-1 mb-3">
                明細
                <button
                  v-show="row.Status < 30 && row.Time"
                  class="button text-theme-1 p-1 ml-auto mr-1"
                  title="新增項目"
                  @click="onAddItem(row)"
                >
                  <FontAwesome class="h-4" icon="plus" />
                </button>
              </div>
              <Grid
                ref="detailGrid"
                v-bind="detailGridOptions"
              >
                <template #column-product-id="{ row: item }">
                  <SelectBox v-if="detailGrid.editingRow == item" :transfer="true" v-model="detailGrid.editingRow.ProductId" v-bind="productSelectOptions" />
                  <span v-else-if="item.Product">{{ item.Product.Name }}</span>
                </template>
              </Grid>
            </div>
          </template>
          <template #modal-footer="{ row }">
            <vxe-button v-if="row && !row.PurchaseBillId && row.WarehouseId && new Date(row.ArrivalDate).valueOf() >= new Date().setHours(0, 0, 0, 0) && row.Amount > 0" type="button" status="warning" content="確認並拋轉進貨單" @click="$refs.form.validate((errMap) => { if (errMap === undefined) { row.PurchaseBillId = -1; $refs.form.dispatchEvent('submit'); } })"></vxe-button>
            <vxe-button v-if="row && row.OriginalStatus < 30" type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button v-if="row && row.OriginalStatus < 30" type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(column, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(column, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { Condition, defineComponent, Operator, reactive, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import { VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    var printColumns = [
      { field: "Number" },
      { field: "Time" },
      { field: "ArrivalDate" },
      { field: "Warehouse.Name" },
      { field: "Submitter.Name" },
      { field: "Amount" },
      { field: "Status" },
    ];

    const gridOptions = reactive<GridOptions>({
      id: "supplier",
      title: "採購單",
      multiselect: false,
      toolbarConfig: { custom: true, refresh: true },
      printConfig: { sheetName: "採購清單", columns: printColumns, modes: ["current", "selected", "all"] },
      exportConfig: { filename: "採購清單", type: "csv", types: ["html", "csv"], mode: "all", modes: ["current", "selected", "all"], columns: printColumns },
      columns: [
        { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, width: 100, sortable: true },
        {
          field: 'Time', title: '採購日期', showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : ""
        },
        {
          field: 'ArrivalDate', title: '到貨日期', showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : ""
        },
        { field: 'Warehouse.Name', title: '入庫倉庫', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Submitter.Name', title: '提交人員', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Amount', title: '金額', showHeaderOverflow: true, showOverflow: true, align: "right", width: 100, sortable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "Status", title: "狀態", showHeaderOverflow: true, showOverflow: true, width: 100, resizable: false, formatter: ({ cellValue }) => model ? Object.values(model.enums.PurchaseOrderStatus).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      decideRowOperable: (row, operation) => row.Status < 30 || operation === 'read',
      promises: {
        query: model ? (params) => model.dispatch("purchaseOrder/query", params) : undefined,
        queryAll: model ? () => model.dispatch("purchaseOrder/query") : undefined,
        save: model ? (params) => model.dispatch("purchaseOrder/save", params) : undefined,
      },
      modalConfig: { width: 800, showFooter: true },
    });

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true } } },
        { field: "Time", title: "採購日期", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "SupplierId", title: "供應商", span: 12, slots: { default: "column-supplier-id" } },
        { field: "WarehouseId", title: "入庫倉庫", span: 12, slots: { default: "column-warehouse-id" } },
        { field: "ShippingDate", title: "預計出貨日", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "ArrivalDate", title: "預計到貨日", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.PurchaseOrderStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'Submitter.Name', title: '提交人員', span: 12, itemRender: { name: '$input', props: { disabled: true } } },
        { field: "Amount", title: "金額", span: 12, itemRender: { name: "$input", props: { type: "number", disabled: true, controls: false } } },
        { field: "PurchaseBill.Number", title: "進貨單", span: 12, itemRender: { name: "$input", props: { disabled: true } } },
      ],
      rules: {
        Time: [{ required: true }],
      }
    };

    const supplierSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇供應商',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, width: 110, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("supplier/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("supplier/query", params) // eslint-disable-line
      },
    }

    const warehouseSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇倉庫',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, width: 110, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("warehouse/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("warehouse/query", params) // eslint-disable-line
      },
    }

    const productSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇產品',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, width: 110, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("product/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("product/query", params) // eslint-disable-line
      },
    }

    const detailGrid = ref<any>({});
    const detailGridOptions = reactive<GridOptions>({
      round: true,
      border: true,
      stripe: true,
      autoResize: true,
      sortConfig: { defaultSort: { field: "CreatedTime", order: "desc" } },
      mode: 'inline',
      multiselect: false,
      columns: [
        {
          field: "ProductId", title: "產品", sortable: true,
          slots: { default: "column-product-id" },
        },
        {
          field: "Price", title: "單價", sortable: true, width: "100", align: "right", resizable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'number', min: 0 },
            events: {
              input: (params, event) => { params.row.Price = event.value; params.row.Amount = params.row.Price * params.row.Quantity; },
            }
          }
        },
        {
          field: "Quantity", title: "數量", sortable: true, width: "100", align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'number', min: 1 },
            events: {
              input: (params, event) => { params.row.Quantity = event.value; params.row.Amount = params.row.Price * params.row.Quantity; },
            }
          }
        },
        {
          field: "Amount", title: "金額", sortable: true, width: "100", align: "right", resizable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
        },
      ],
      editRules: {
        ProductId: [{ required: true, message: "未選擇產品" }],
        Price: [
          { required: true, message: '未輸入單價' },
          { min: 0, message: '單價不得為負數' }
        ],
        Quantity: [
          { required: true, message: '未輸入數量' },
          { min: 1, message: '數量需大於零' }
        ],
      },
      promises: {
        query: model ? (params) => { params.condition = new Condition("PurchaseOrderId", Operator.Equal, grid.value.editingRow?.Id || 0).and(params.condition!); return model.dispatch("purchaseOrderItem/query", params); } : undefined,
        queryAll: model ? () => model.dispatch("purchaseOrderItem/query") : undefined,
        save: model ? (params) => model.dispatch("purchaseOrderItem/save", params).then(async () => { grid.value.editingRow.Amount = (await model.dispatch('purchaseOrder/find', grid.value.editingRow.Id)).Amount; grid.value.refresh(); }) : undefined,
      },
      modalConfig: { height: "auto" },
    });

    return {
      grid,
      gridOptions,
      formOptions,
      supplierSelectOptions,
      warehouseSelectOptions,
      productSelectOptions,
      detailGrid,
      detailGridOptions,
    };
  },
  methods: {
    hideBatchDropDown () {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onAddItem(masterRow: any) {
      if (!masterRow.Id) {
        if (!confirm("新增項目須先建立此張採購單，是否同意?")) return;
        try {
          const bill = await this.$model.dispatch("purchaseOrder/insert", masterRow);
          Object.assign(masterRow, bill);
        } catch (e: any) {
          this.$send('error', e);
        }
      }
      this.detailGrid.addNewRow({
        PurchaseOrderId: this.grid.editingRow.Id,
        Quantity: 1,
        Amount: 0
      });
    },
  }
});
</script>
